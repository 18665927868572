<template>
  <div>
    <a-drawer
      :title="title"
      :width="600"
      :visible="visible"
      @close="cancel"
      :footer="null"
      :wrapStyle="{overflow: 'auto'}"
      destroyOnClose
    >
    <a-row class="mb24x">
      <a-col :span="5" class="txR">题型：</a-col>
      <a-col :span="15">
        <a-input v-model="data.title"/>
      </a-col>
    </a-row>
    <a-row class="mb24x">
      <a-col :span="5" class="txR">题型描述：</a-col>
      <a-col :span="15">
        <a-input v-model="data.describe"/>
      </a-col>
    </a-row>
    <a-row class="mb24x">
      <a-col :span="5" class="txR">题型选择：</a-col>
      <a-col :span="15">
        <a-select v-model="data.type" style="width:100%" placeholder="请选择题型" @change="handleChange">
          <a-select-option :value="item.val" v-for="(item, index) in taskType" :key="index">
              {{item.name}}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <div v-if="data.type == 'material'">
      <a-row class="mb24x">
        <a-col :span="5" class="txR">习题设置：</a-col>
        <a-col :span="15">
        </a-col>
      </a-row>
      <a-row class="mb24x" :gutter="10" v-for="(el,index) in data.materialTask" :key="index">
        <a-col :span="3" class="txR">
        </a-col>
        <a-col :span="6" class="txR">
          <a-select v-model="el.type" style="width:100%" placeholder="请选择题型">
            <a-select-option :value="item.val" v-for="(item, indexA) in materialTaskType" :key="indexA">
                {{item.name}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4">
          <div class="inputWrap">
            <a-input
              :value="el.num"
              :maxLength="25"
              class="hasText tText"
              @change="el.num=numDetector($event)"
            />
            <div class="rightTest">题</div>
          </div>
        </a-col>
      <a-col :span="4">
          <div class="inputWrap">
              <a-input
                      :value="el.each_score"
                      :maxLength="25"
                      class="hasText tText"
                      @change="el.each_score=numDetector($event)"
              />
              <div class="rightTest">分/题</div>
          </div>
      </a-col>
      <a-col :span="4">
          <div class="inputWrap">
              <a-input
                      :value="computedNum( el.num, el.each_score )"
                      disabled
                      :maxLength="25"
                      class="hasText tText"
              />
              <div class="rightTest">总分</div>
          </div>
      </a-col>
        <a-col :span="3" >
          <a-icon type="plus-circle" style="font-size:24px;color:#4165e0;cursor:pointer;margin-top:3px;margin-right:5px" @click="addMaterial"/>
          <a-icon type="minus-circle" style="font-size:24px;color:#4165e0;cursor:pointer;margin-top:3px" v-if="index!=0" @click="data.materialTask.splice(index,1)"/>
        </a-col>
      </a-row>
    </div>
    <div v-if="data.type == 'answer'">
       <a-row class="mb24x">
        <a-col :span="5" class="txR">习题设置：</a-col>
        <a-col :span="15">
          <span style="color:#1890ff">自定义分值设置及答题数</span>
        </a-col>
      </a-row>
      <a-row class="mb24x">
         <a-col :span="3" class="txR"></a-col>
         <a-col :span="18">
          <a-row type="flex" justify="center" align="top" :gutter="10">
              <a-col :span="6">
                <div class="inputWrap">
                  <a-input
                    :value="data.num"
                    :maxLength="25"
                    class="hasText tText"
                    @change="data.num=numDetector($event)"
                  />
                  <div class="rightTest">题</div>
                </div>
              </a-col>
              <a-col :span="6">
                  <div class="inputWrap">
                      <a-input
                              :value="data.each_score"
                              :maxLength="25"
                              class="hasText tText"
                              @change="data.each_score=numDetector($event)"
                      />
                      <div class="rightTest">分</div>
                  </div>
              </a-col>
              <a-col :span="6">
                <div class="inputWrap">
                  <a-input
                    :value="data.answerNum"
                    :maxLength="25"
                    class="hasText tText"
                    @change="data.answerNum=numDetector($event)"
                  />
                  <div class="rightTest" style="width:60px">答题数</div>
                </div>
              </a-col>
              <a-col :span="6">
                  <div class="inputWrap">
                      <a-input
                              :value="computedNum( data.answerNum, data.each_score )"
                              disabled
                              :maxLength="25"
                              class="hasText tText"
                      />
                      <div class="rightTest">总分</div>
                  </div>
              </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
     <div v-if="data.type != 'material' && data.type != 'answer'">
      <a-row class="mb24x">
        <!--<a-row class="mb24x">
          <a-col :span="5" class="txR">题型描述：</a-col>
          <a-col :span="15">
            <a-input v-model="data.describe"/>
          </a-col>
        </a-row>-->
        <a-col :span="5" class="txR">习题设置：</a-col>
          <a-col :span="15">
              <a-row type="flex" justify="center" align="top" :gutter="10">
                  <a-col :span="8">
                      <div class="inputWrap">
                          <a-input
                                  :value="data.num"
                                  :maxLength="25"
                                  class="hasText tText"
                                  @change="data.num=numDetector($event)"
                          />
                          <div class="rightTest">题</div>
                      </div>
                  </a-col>
                  <a-col :span="8">
                      <div class="inputWrap">
                          <a-input
                                  :value="data.each_score"
                                  :maxLength="25"
                                  class="hasText tText"
                                  @change="data.each_score=numDetector($event)"
                          />
                          <div class="rightTest">分/题</div>
                      </div>
                  </a-col>
                  <a-col :span="8">
                      <div class="inputWrap">
                          <a-input
                                  :value="computedNum( data.num, data.each_score )"
                                  disabled
                                  :maxLength="25"
                                  class="hasText tText"
                          />
                          <div class="rightTest">总分</div>
                      </div>
                  </a-col>
              </a-row>
          </a-col>
      </a-row>
    </div>
    <div style="text-align:right;marign:20px 0">
        <a-button
          type="primary"
         @click="handleSubmit"
        >
            确定
        </a-button>
    </div>
    </a-drawer>
  </div>
</template>
<script>
import {taskType} from '@/libs/transform'
export default {
  name:'addMicroType',
  props:['show', 'editData'],
  watch:{
    show(val){
      this.visible = val
      if(val){
        if(this.editData){
          this.title="编辑题型"
          this.data = JSON.parse(JSON.stringify(this.editData))
        }else{
          this.title="新增题型"
        }
      }else{
        this.reset()
      }
    }
  },
  data() {
    return {
      title:'新增题型',
      taskType:taskType(),
      materialTaskType:taskType(null,true),
      visible: this.show,
      data:{
        title:'',
        describe:'',
        type:'single_choice',
        num:'',
        answerNum: '',
        each_score:'',
        materialTask:[
          {
            type:'single_choice',
            num:'',
            each_score:'',
          }
        ],
      }
    }
  },
  methods: {
    reset(){
      this.data={
        title:'',
        describe:'',
        type:'single_choice',
        num:'',
        answerNum: '',
        each_score:'',
        materialTask:[
          {
            type:'single_choice',
            num:'',
            each_score:'',
          }
        ],
      }
    },
    addMaterial(){
      this.data.materialTask.push({type:'single_choice', num:'', each_score:'',})
    },
    verify(){
      if(!this.data.title){
        this.$message.error('请输入题型！')
        return false
      }
      if(!this.data.describe){
        this.$message.error('请输入题型描述！')
        return false
      }
      return true
    },
    computedNum( val1, val2 ){
        if( val1 && val2 ){
          return val1*val2
        }else{
          return null
        }
    },
    handleChange(value){
        this.data.num = ''
        this.data.each_score = ''
        this.materialTask = [
          {
            type:'single_choice',
            num:'',
            each_score:'',
          }
        ]
    },
    handleOk(e) {
      this.visible = false
    },
    cancel() {
      this.$emit('close',false)
    },
    numDetector(e){
      const { value } = e.target;
      const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        return value
      }
    },
    handleSubmit(e){
      e.preventDefault();
      if(this.verify()){
        if(this.editData){
          this.$emit('edit',this.data)
        }else{
          this.$emit('submit',this.data)
        }
        this.cancel()
      }
    }

  }
}
</script>
<style lang="less" scoped>
  .inputWrap{
    padding-left: 0;
    position: relative;
    .rightTest{
      position: absolute;
      right: 0;
      width: 50px;
      font-size: 17px;
      color: #999999;
      top: 0;
      line-height:32px;
      padding-right: 8px;
      text-align: right
    }
  }
  .txR{text-align: right}
  .mb24x{margin-bottom: 24px}
</style>
